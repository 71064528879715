<template>
  <div
    class="intro-container d-flex flex-column h-100 position-relative bg-bgsection"
    @click="$refs.introCarousel.next()"
  >
    <LoginHeader v-if="$device.isMobile">
      <p
        class="m-0 mr-3 font-weight-bold text-secondary font-weight-bold"
        @click="onClickLogin"
      >
        {{ $t('登入') }}
      </p>
    </LoginHeader>

    <div
      v-if="!$device.isMobile"
      class="web-logo-wrapper position-absolute"
      @click.stop="onClickLogin"
    >
      <img :src="BaseLogo" width="100" alt="logo" />
    </div>
    <div v-if="!$device.isMobile" class="web-btn-wrapper position-absolute">
      <b-button
        variant="white"
        class="font-weight-bold"
        size="lg"
        @click.stop="onClickLogin"
      >
        {{ $t('登入') }}
      </b-button>
      <b-button
        variant="secondary"
        size="lg"
        class="ml-3 font-weight-bold"
        @click.stop="onClickSignUp"
      >
        {{ $t('免費註冊') }}
      </b-button>
    </div>

    <div class="carousel-container position-relative h-100">
      <div
        v-show="slide !== 0"
        class="control control-left"
        @click.stop="$refs.introCarousel.prev()"
      >
        <i class="icon-Chevron-left text-primary cursor-pointer"></i>
      </div>

      <b-carousel
        id="carousel-1"
        ref="introCarousel"
        v-model="slide"
        :interval="4000"
        indicators
        class="w-100 h-100"
        no-wrap
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide>
          <template #img>
            <div
              class="slide-img-wrapper d-flex flex-column justify-content-center h-100"
            >
              <div class="img-wrapper mx-auto">
                <img
                  class="d-block img-fluid"
                  :srcset="require('@/assets/img/login/intro_1_jia.png?srcset')"
                  :style="{'object-fit': 'cover'}"
                />
              </div>
              <div class="bottom-bg text-center">
                <div
                  class="caption text-20 font-weight-bold d-flex align-items-center justify-content-center"
                >
                  {{ $t('純棋 10分鐘學會下圍棋') }}
                </div>
                <div
                  class="carousel-content text-16 d-flex align-items-center justify-content-center"
                >
                  {{ $t('不分男女老少，大家都可以一起同樂') }}
                </div>
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <div
              class="slide-img-wrapper d-flex flex-column justify-content-center h-100"
            >
              <div class="img-wrapper mx-auto">
                <img
                  class="d-block img-fluid"
                  :srcset="require('@/assets/img/login/intro_2_jia.png?srcset')"
                  :style="{'object-fit': 'cover'}"
                />
              </div>
              <div class="bottom-bg">
                <div
                  class="caption text-20 font-weight-bold d-flex align-items-center justify-content-center"
                >
                  {{ $t('可免費遊玩的益智遊戲') }}
                </div>
                <div
                  class="carousel-content text-16 d-flex align-items-center justify-content-center"
                >
                  {{ $t('邊玩邊訓練大腦、保持智力活躍') }}
                </div>
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <div
              class="slide-img-wrapper d-flex flex-column justify-content-center h-100"
            >
              <div class="img-wrapper mx-auto">
                <img
                  class="d-block img-fluid"
                  :srcset="require('@/assets/img/login/intro_3_jia.png?srcset')"
                  :style="{'object-fit': 'cover'}"
                />
              </div>
              <div class="bottom-bg">
                <div
                  class="caption text-20 font-weight-bold d-flex align-items-center justify-content-center"
                >
                  {{ $t('現在馬上開始學！') }}
                </div>
                <b-button
                  variant="primary"
                  class="start-button mx-auto d-block mt-2 text-20"
                  style="line-height: 34px"
                  size="md"
                  @click="onClickLogin"
                >
                  {{ $t('開始') }}
                </b-button>
              </div>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>

      <div
        v-show="slide !== 2"
        class="control control-right"
        @click.stop="$refs.introCarousel.next()"
      >
        <i class="icon-Chevron-right text-primary cursor-pointer"></i>
      </div>
    </div>

    <CopyrightFooter />
  </div>
</template>

<script>
import {saveLocalData} from '@/lib/base/localData';
import LoginHeader from '@/components/Base/LoginHeader.vue';
import CopyrightFooter from '@/components/Base/CopyrightFooter.vue';
import BaseLogo from '@/assets/img/login/base-logo-white.png';
export default {
  components: {LoginHeader, CopyrightFooter},
  data() {
    return {
      slide: 0,
      sliding: null,
      saveLocalData,
      BaseLogo,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    onClickLogin() {
      this.saveLocalData('hasIntroShow', true);
      this.$router.push({name: 'login'});
    },
    onClickSignUp() {
      this.saveLocalData('hasIntroShow', true);
      this.$router.push({name: 'signup'});
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-container {
  .bottom-bg {
    .caption {
      line-height: 34px;
    }
    .carousel-content {
      line-height: 28px;
      color: $font-grayscale-2;
    }
    .start-button {
      width: 240px;
    }
  }
  .img-wrapper {
    width: calc(100vw - 64px - 7px - 16px);
    margin-bottom: 40px;
    @media screen and (min-width: 768px) {
      width: 50vh;
      height: 50vh;
    }
  }
  /deep/ .carousel-inner {
    height: 100%;
    .carousel-item {
      height: 100%;
    }
  }
  /deep/ .carousel-indicators {
    position: absolute;
    bottom: 12px;
  }
  /deep/ ol {
    margin-block-end: 0;
    li {
      background-color: $primary;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
  }
  .control {
    z-index: 10;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    @media screen and (min-width: 768px) {
      height: auto;
      top: 50%;
    }
    i {
      font-size: 32px;
      @media screen and (min-width: 768px) {
        font-size: 60px;
      }
      @media screen and (min-width: 1440px) {
        font-size: 80px;
      }
    }
  }
  .control-left {
    left: 3.5px;
    @media screen and (min-width: 768px) {
      left: calc(50% - 25vh - 16px - 60px);
    }
  }
  .control-right {
    right: 3.5px;
    @media screen and (min-width: 768px) {
      right: calc(50% - 25vh - 16px - 60px);
    }
  }
  .web-logo-wrapper {
    z-index: 10;
    top: 32px;
    left: 32px;
  }
  .web-btn-wrapper {
    z-index: 10;
    top: 40px;
    right: 40px;
    button {
      width: 232px;
    }
  }
}
</style>
